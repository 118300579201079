@import "variables.scss";

h1, h2, h3, h4, h5, h6 {
 font-family: $main-font-oswald;
}

h1 {
    font-size: 2em;
}

h2, h3 {
    font-size: 1.8em;
}

h4, h4, h6 {
    font-size: 1.5em;
}

p {
    margin: 0px;
    padding: 0px;
}