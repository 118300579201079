/// Global Variables
@import url("https://fonts.googleapis.com/css2?family=Oswald&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

$primary-color-blue: #2489ce;

/// Grey
$grey-main: #2c2c2c;
$grey-light: #5f5f5f;

$white: #fff; 

// Fonts 

$main-font-oswald: "Oswald", sans-serif;
$sub-font-roboto: "Roboto", sans-serif;