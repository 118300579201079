.ul-item-styles {
    list-style: none;
    padding: 0;    
}

.mui-icons-align__portfolio {
    vertical-align: middle;
}

button.portfolio-btn-styles {
    width: auto;
}

button.portfolio-btn-delete-styles {
    width: auto;
}

button.portfolio-btn-edit-styles {
    width: auto;
}
