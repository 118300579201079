@import url("https://fonts.googleapis.com/css2?family=Oswald&display=swap");

footer {
    font-family: "Oswald", sans-serif;
    color: #bcbcbc;
    margin-top: 20px;
}

.main-footer-wrapper {
 background-color: #2c2c2c;
 padding: 32px 0px;
 display: flex;
 flex-direction: row;
}

.container-footer-wrap {
    display: flex !important;
}


.footer-widget-area {
    width: 25%;
}
.footer-widget-area > h4 {
    color: #fff;
}

.footer-widget-area a {
    color: #bcbcbc;
}

.footer-widget-area a:hover {
    color: #fff;
}

.footer-widget-area ul li {
    list-style: none;
}

.footer-widget-area address {
    padding-left: 40px;
}

.main-sub-footer {
    background-color: #d9d9d8;
    padding: 10px 0px;
}

.wrapper-sub-footer {
    color: #707070;
    display: flex;
    justify-content: space-between;
}

p.p-tag-space {
    margin: 0px;
}

.sub-footer-area {
    text-align: left;
}

.sub-footer-area2 {
    text-align: right;
}

@media(max-width: 800px) {
    
    .container-footer-wrap {
        display: block !important;
    }
    .footer-widget-area {
        width: 100%;
    }
    .wrapper-sub-footer {
        color: #707070;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
}