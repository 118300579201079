@import "../../scss/variables.scss";

.container-header-wrapper {
   display: flex;
   justify-content: space-between;
}

.menu-wrapper-styles {
   width: 100%;
   display: inline-flex;
   align-items: center;
}


.menu-wrapper-styles ul > li {
   width: auto;
}

a.a-link-item {
   color: #5f5f5f;
   text-decoration: none; 
   text-transform: uppercase;
   font-size: 13px;
   font-family: $main-font-oswald;;
}

a.a-link-item:hover, a.a-link-item:active {
   color: #2489ce;
   text-decoration: underline;
}

.container-header-styles {
   border-bottom: 1px solid rgba(0,0,0,.1);
}

figure.logo-image-specs {
   width: auto;
}


.orientation-menu-desktop {
   color: #000;
   display: flex;
   align-content: center;
}

.aside-menu-styles {
  display: flex;
  text-align: right;
}

li.li-list-styles {
   width: auto;
}


header.header-wrapper-shadow {
   border-bottom: 1px solid rgba(0,0,0,.1);
}

   button.mobile-menu-wrapper {
      display: none;
   } 

.burger-wrapper-styles {
   display: flex;
   flex-direction: column;
   width: 150px;
   margin: 10px !important;
}

.close-button-styles {
   padding: 10px;
   text-align: right;
}

@media(max-width: 800px) {

   ul.menu-wrapper-styles {
      display: none;
   }

   button.mobile-menu-wrapper {
      display: block;
   } 

}