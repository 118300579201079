.portfolio-field-styles {
    padding: 0 50px;
    margin: 15px;
}

.button-form-styles {
    width: 120px;
    margin: 15px 0;
    margin-left: auto;
    margin-right: auto;
}

ul.menu-ul-item-styles {
   list-style: none;
    text-align: left;
}

ol.menu-ul-item-styles {
     text-align: left;
 }

ul.ul-item-styles li {
    display: inline;
}

button.portfolio-delete-btn-styles {
    width: 10px !important;
}
